export default () => ({
    activeTab: 1,

    toggleMenu() {
        this.open = !this.open;
    },

    setActiveTab(id) {
        this.activeTab = id;
        console.log(id);
    }
});
