import './bootstrap.js';
import './swup.js'

import Alpine from 'alpinejs';
// import focus from '@alpinejs/focus';
// import collapse from '@alpinejs/collapse';
// import intersect from '@alpinejs/intersect'

import tabs from './components/tabs';

window.Alpine = Alpine;
// Alpine.plugin(collapse);
// Alpine.plugin(focus);
// Alpine.plugin(intersect);

document.addEventListener('alpine:init', () => {
    Alpine.data('tabs', tabs);
});

Alpine.start();
